import {
  ApiResources,
  ApiVersion,
  VoucherAppliedToType,
  VoucherDiscountType,
  VoucherRedemptionPerUserLimit,
  VoucherType,
} from '../../enums';
import { EnumHelper } from '../../helpers';
import { AbstractModel, AbstractModelData } from '../abstract.model';
import { ISchema, Type, Widget } from '../schema';

export interface VoucherDiscount {
  type: VoucherDiscountType;
  appliedTo: VoucherAppliedToType;
  percentageOff?: number; // Decimal 0.02 === 2%
  amountMin?: number; // Min order value before coupon can be used
  amountLimit?: number; // Max value off the order when coupon is used;
  amountOff?: number; // The fixed $ amount off the order
  unitOff?: number; // The number of units that can be applied e.g. BOGO
  menuItemCategory?: string[];
  menuItem?: string[];
}

export interface GiftCard {
  amount: number; // The initial value of the gift card
  balance: number; // the current balance of the gift card
}

export interface VoucherRedemption {
  // How many times a coupon can be redeemed, NULL for unlimited
  quantity: number | null;
  oncePerUser: boolean; // How many times a user can redeem a coupon
  perUserLimit: VoucherRedemptionPerUserLimit; // How many times a user can redeem a coupon over a period of time
  redeemedQuantity: number; // How many times this coupon has been redeemed
  redeemedAmount: number; // The total value of redemptions
  sales: number; // The total value of sales
}

export interface VoucherSchemaData {
  type: VoucherType;
  code: string;

  discount?: VoucherDiscount;
  gift?: GiftCard;

  redemption: VoucherRedemption;

  startDate: string;
  endDate?: string;
  /**
   * This is for exploding vouchers which end in a duration from a set time
   * e.g. For the next 15 mins all orders are 15% off
   *
   * Use Case:
   * When customers team scores, they may want to enable discounts to all orders
   */
  explodingTime?: number;
  endTime?: Date;

  isActive: boolean;

  orgId?: string;
  user?: string;
  venue?: string;
  restaurant?: string;

  isExploding: boolean;
}

export type VoucherData = VoucherSchemaData & AbstractModelData;

export class Voucher extends AbstractModel<VoucherData> {}

export const VoucherDiscountSchema: ISchema<VoucherDiscount> = {
  type: {
    section: 'Basic Info',
    type: Type.STRING,
    label: 'Type',
    widget: {
      type: Widget.SELECT,
      enum: EnumHelper.getEnumArray(VoucherDiscountType),
    },
  },
  appliedTo: {
    section: 'Basic Info',
    type: Type.STRING,
    label: 'Applied To',
    widget: {
      type: Widget.SELECT,
      enum: EnumHelper.getEnumArray(VoucherAppliedToType),
    },
    onChange(value, data) {
      data.menuItem = [];
      data.menuItemCategory = [];
    },
  },
  percentageOff: {
    section: 'Basic Info',
    type: Type.NUMBER,
    label: 'Percentage Off',
    onlyIf: [
      {
        field: 'type',
        shouldShow: (value: VoucherDiscountType) => value === VoucherDiscountType.PERCENTAGE,
      },
    ],
  },
  amountMin: {
    section: 'Basic Info',
    type: Type.CURRENCY,
    label: 'Amount Min',
    description: 'Min order value before coupon can be used',
    default: 0,
  },
  amountLimit: {
    section: 'Basic Info',
    type: Type.CURRENCY,
    label: 'Amount Limit',
    description: 'Max value off the order when coupon is used',
    onlyIf: [
      {
        field: 'type',
        shouldShow: (value: VoucherDiscountType) => value === VoucherDiscountType.PERCENTAGE,
      },
    ],
  },
  amountOff: {
    section: 'Basic Info',
    type: Type.CURRENCY,
    label: 'Amount Off',
    description: 'The fixed $ amount off the order',
    onlyIf: [
      {
        field: 'type',
        shouldShow: (value: VoucherDiscountType) => value === VoucherDiscountType.AMOUNT,
      },
    ],
  },
  unitOff: {
    section: 'Basic Info',
    type: Type.NUMBER,
    label: 'Unit Off',
    description: 'The number of units that can be applied e.g. BOGO',
    onlyIf: [
      {
        field: 'type',
        shouldShow: (value: VoucherDiscountType) => value === VoucherDiscountType.UNIT,
      },
    ],
  },
  menuItemCategory: {
    section: 'Basic Info',
    type: Type.ARRAY,
    label: 'Menu Item Category',
    description: 'Limit the menu item categories that the discount applies to',
    widget: {
      type: Widget.SELECT,
      enum: [],
      multiple: true,
      options: {
        resource: ApiResources.CATEGORY,
        version: ApiVersion.V2,
        subCollection: 'self',
        modelProp: 'name',
        dataProp: 'categories',
        search: { sort: 'name', limit: 100 },
        displayFields: ['name'],
      },
    },
    onlyIf: [
      {
        field: 'appliedTo',
        shouldShow: (appliedTo: VoucherAppliedToType) =>
          appliedTo === VoucherAppliedToType.CATEGORY,
      },
    ],
  },
  menuItem: {
    section: 'Basic Info',
    type: Type.ARRAY,
    label: 'Menu Item',
    description: 'Limit the menu items that the discount applies to',
    widget: {
      type: Widget.SELECT,
      multiple: true,
      enum: [],
      options: {
        resource: ApiResources.MENU_ITEMS,
        version: ApiVersion.V2,
        subCollection: 'self',
        search: { limit: 10000, sort: 'name' },
        dataProp: 'menuItems',
        modelProp: 'name',
        displayFields: ['name', 'category.name'],
      },
    },
    onlyIf: [
      {
        field: 'appliedTo',
        shouldShow: (appliedTo: VoucherAppliedToType) => appliedTo === VoucherAppliedToType.ITEM,
      },
    ],
  },
};

export const VoucherGiftSchema: ISchema<GiftCard> = {
  amount: {
    section: 'Basic Info',
    type: Type.NUMBER,
    label: 'Amount',
    description: 'The initial value of the gift card',
    onChange: (value: number, data: GiftCard) => {
      data.balance = value;
    },
  },
  balance: {
    section: 'Basic Info',
    type: Type.NUMBER,
    label: 'Balance',
    isHidden: true,
  },
};

export const VoucherRedemptionSchema: ISchema<VoucherRedemption> = {
  quantity: {
    section: 'Basic Info',
    type: Type.NUMBER,
    label: 'Quantity',
    description: 'How many times a coupon can be redeemed, leave blank for unlimited',
    default: null,
  },
  oncePerUser: {
    section: 'Basic Info',
    type: Type.BOOLEAN,
    label: 'Once Per User',
    description: 'Limit the number of times a user can redeem this coupon.',
  },
  perUserLimit: {
    section: 'Basic Info',
    type: Type.STRING,
    label: 'Per User Limit',
    description: 'Set the users once per user limit.',
    widget: {
      type: Widget.SELECT,
      enum: EnumHelper.getEnumArray(VoucherRedemptionPerUserLimit),
    },
    onlyIf: [
      {
        field: 'oncePerUser',
        shouldShow: (oncePerUser: boolean) => oncePerUser,
      },
    ],
  },
  redeemedQuantity: {
    section: 'Basic Info',
    type: Type.NUMBER,
    label: 'Redeemed Quantity',
    default: 0,
    isHidden: true,
  },
  redeemedAmount: {
    section: 'Basic Info',
    type: Type.NUMBER,
    label: 'Redeemed Amount',
    default: 0,
    isHidden: true,
  },
  sales: {
    section: 'Basic Info',
    type: Type.NUMBER,
    label: 'Sales',
    default: 0,
    isHidden: true,
  },
};

export const VoucherSchema: ISchema<VoucherSchemaData> = {
  type: {
    section: 'Basic Info',
    type: Type.STRING,
    label: 'Type',
    widget: {
      type: Widget.SELECT,
      enum: EnumHelper.getEnumArray(VoucherType),
    },
  },
  code: {
    section: 'Basic Info',
    label: 'Code',
    type: Type.STRING,
    immutable: true,
  },
  isActive: {
    section: 'Basic Info',
    type: Type.BOOLEAN,
    label: 'Is Active',
    description: 'Whether the voucher is active',
  },
  discount: {
    section: 'Discount',
    type: Type.OBJECT,
    label: 'Options',
    properties: VoucherDiscountSchema,
    onlyIf: [
      {
        field: 'type',
        shouldShow: (value: VoucherType) =>
          [VoucherType.DISCOUNT, VoucherType.MEMBER_DISCOUNT].includes(value),
      },
    ],
  },
  isExploding: {
    section: 'Duration Info',
    type: Type.BOOLEAN,
    label: 'Is Exploding',
    description: 'Expire in Mins from Update',
    default: false,
    onlyIf: [
      {
        field: 'type',
        shouldShow: (value: VoucherType) => value === VoucherType.DISCOUNT,
      },
    ],
  },
  explodingTime: {
    section: 'Duration Info',
    type: Type.NUMBER,
    label: 'Mins to Expiry',
    description:
      'This is for exploding vouchers which end in a duration from a set time. E.g. For the next 15 mins all orders are 15% off',
    onlyIf: 'isExploding',
  },
  endTime: {
    section: 'Duration Info',
    type: Type.DATE,
    label: 'End Time',
    description: 'The end time of the exploding voucher',
    isHidden: true,
  },
  gift: {
    section: 'Gift',
    type: Type.OBJECT,
    label: 'Options',
    properties: VoucherGiftSchema,
    onlyIf: [
      {
        field: 'type',
        shouldShow: (value: VoucherType) => value === VoucherType.GIFT,
      },
    ],
  },
  redemption: {
    section: 'Redemption',
    type: Type.OBJECT,
    label: 'Options',
    properties: VoucherRedemptionSchema,
    onlyIf: [
      {
        field: 'type',
        shouldShow: (value: VoucherType) =>
          [VoucherType.DISCOUNT, VoucherType.MEMBER_DISCOUNT].includes(value),
      },
    ],
  },
  startDate: {
    section: 'Dates',
    type: Type.DATE,
    label: 'Start Date',
    description: 'The start date of the voucher',
    notIf: 'isExploding',
  },
  endDate: {
    section: 'Dates',
    type: Type.DATE,
    label: 'End Date',
    description: 'The end date of the voucher, leave blank for no end date',
    notIf: 'isExploding',
  },
  orgId: {
    section: 'Entity Mapping',
    type: Type.STRING,
    label: 'Org ID',
    description: 'The org ID of the voucher',
    widget: {
      type: Widget.SELECT,
      options: {
        version: ApiVersion.V2,
        resource: ApiResources.ORGS,
        modelProp: 'name',
        displayFields: ['name'],
      },
    },
    partakeOnly: true,
  },
  venue: {
    section: 'Entity Mapping',
    type: Type.STRING,
    label: 'Venue ID',
    description: 'The venue ID of the voucher',
    widget: {
      type: Widget.SELECT,
      options: {
        version: ApiVersion.V2,
        resource: ApiResources.VENUES,
        modelProp: 'name',
        displayFields: ['name'],
      },
    },
    isHidden: true,
  },
  restaurant: {
    section: 'Entity Mapping',
    type: Type.STRING,
    label: 'Restaurant ID',
    description: 'The restaurant ID of the voucher',
    widget: {
      type: Widget.SELECT,
      options: {
        version: ApiVersion.V2,
        resource: ApiResources.RESTAURANTS,
        modelProp: 'name',
        displayFields: ['name'],
      },
    },
    isHidden: true,
  },
  user: {
    section: 'Entity Mapping',
    type: Type.STRING,
    label: 'User ID',
    description: 'The user ID of the voucher',
    widget: {
      type: Widget.SELECT,
      options: {
        version: ApiVersion.V2,
        resource: ApiResources.USERS,
        modelProp: 'name',
        displayFields: ['name', 'email', 'phone'],
      },
    },
    isHidden: true,
  },
};
