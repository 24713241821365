export enum ApiResources {
  // API
  ADMIN = 'admin',
  AUTH = 'auth',
  ACCOUNTING_CODE = 'accounting-code',
  ACTIVITY_RECORD = 'activity-record',
  ALERTS = 'alert',
  ATTRACTIONS = 'attractions',
  ARENAS = 'arena',
  CATEGORY = 'category',
  CLASSIFICATIONS = 'classifications',
  CLOUDFLARE = 'cloudflare',
  COURSES = 'course',
  CUSTOM_CODE = 'custom-code',
  DAY_PART = 'day-part',
  DINING_OPTIONS = 'dining-options',
  DISCOUNTS = 'discount',
  EMAILS = 'emails',
  EMPLOYEE = 'employee',
  EVENTS = 'events',
  FAVORITES = 'favorite',
  FLOOR_PLAN = 'floor-plan',
  GAMES = 'game',
  GAME_ENTRIES = 'game-entry',
  GAME_QUESTION = 'game-question',
  GAME_RULES = 'game-rules',
  GUESTS = 'guests',
  GRAPHQL = 'graphql',
  GRATUITIES = 'gratuities',
  GRATUITY_MAPS = 'gratuity-map',
  HEADS_UP = 'heads-up',
  HELP = 'help',
  HEROKU = 'heroku',
  IMAGES = 'images',
  INTEGRATIONS = 'integrations',
  INVENTORY = 'inventory-item',
  INVENTORY_LOGS = 'inventory-log',
  JOB = 'job',
  LOCATION = 'location',
  LOCATION_CATEGORY = 'location-category',
  LOCATION_GROUP = 'location-group',
  LOG = 'log',
  MAGIC_COPY = 'magic-copy',
  MATCHES = 'match',
  MENU = 'menu',
  MENU_SECTION = 'menu-section',
  MENU_ITEMS = 'menu-item',
  MENU_ITEM_MODIFIER = 'menu-item-modifier',
  MENU_ITEM_STATION = 'menu-item-station',
  NOTIFICATION = 'notifications',
  ORDERS = 'order',
  ORGS = 'org',
  PAYMENT = 'payment',
  PICK_EMS = 'pick-em',
  POSTS = 'post',
  POINT_OF_SALE = 'point-of-sale',
  PRINTER = 'printer',
  PRIZES = 'prizes',
  PRODUCT = 'product',
  PRODUCT_UPDATE = 'product-update',
  PROMOTIONS = 'promotions',
  PROPS = 'props',
  MORE_LESS = 'more-less',
  PRICING_CONFIGS = 'pricing-config',
  /** @deprecated use `location` instead */
  RESTAURANTS = 'restaurant',
  /** @deprecated use `reporting` instead */
  REPORTS = 'reports',
  REPORTING = 'reporting',
  SHIFT = 'shift',
  SERVICE_CHARGE = 'service-charge',
  SPORTS = 'sports',
  STAND_SHEET = 'stand-sheet',
  STRIPE = 'stripe',
  SUMMARY_DATA = 'summary-data',
  SYNC_ERRORS = 'sync-errors',
  TAGS = 'tags',
  USER = 'user',
  USER_ROLE = 'user-role',
  USERS = 'users',
  SUBSCRIPTION = 'subscription',
  TAX_CODES = 'tax-codes',
  TENDER = 'tender',
  TENDER_MAPS = 'tender-map',
  TRIVIA = 'trivia',
  VENUES = 'venue',
  WHITE_LABELS = 'white-labels',
  VOUCHER = 'voucher',

  // TEE SHEET
  GREEN_FEE_RATE = 'green-fee-rate',
  TEE_TIME = 'tee-time',
  TEE_TIME_BOOKING = 'tee-time-booking',
  TEE_SHEET = 'tee-sheet',
  TEE_SHEET_SETTINGS = 'tee-sheet-settings',

  // STATS
  COMPETITION = 'competition',
  CONTESTANT = 'contestant',
  COUNTRY = 'country',
  FIXTURE = 'fixture',
  MATCH = 'match',
  PERSON_MATCH_STATS = 'person-match-stats',
  PERSON_SEASON_STATS = 'person-season-stats',
  PERSON = 'person',
  ROSTER = 'roster',
  RULE_SET = 'rule-set',
  SPORT = 'sport',
  STATS_VENUE = 'stats-venue',
  TOURNAMENT_CALENDAR = 'tournament-calendar',
  TOURNAMENT_STAGE = 'tournament-stage',

  // EZLINKS
  EZ_DEPARTMENT = 'ez-department',
  EZ_DEPARTMENT_MENU = 'ez-department-menus',
  EZ_DISCOUNTS = 'ez-discounts',
  EZ_EMPLOYEES = 'ez-employees',
  EZ_GRATUITIES = 'ez-gratuties',
  EZ_LOGIN = 'ez-login',
  EZ_MENU_ITEMS = 'ez-inventory-items',
  EZ_MEMBERS = 'ez-members',
  EZ_SYNC = 'ez-sync',
  EZ_SYNC_ORDER = 'ez-sync-order',
  EZ_TAX_CODES = 'ez-tax-codes',
  EZ_TENDERS = 'ez-tenders',
  EZ_TICKET = 'ez-ticket',
}
