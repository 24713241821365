import { ApiResources, ApiVersion, EventPriceCategory, EventType } from '../../enums';
import { EnumHelper, TimeHelper } from '../../helpers';
import { AbstractModel, AbstractModelData } from '../abstract.model';
import { IImage } from '../apps';
import { OrgData, VenueData } from '../core';
import { ISchema, Type, Widget } from '../schema';

const DateTimeSchema: ISchema = {
  date: {
    type: Type.STRING,
    label: 'Date String',
    isHidden: true,
  },
  time: {
    type: Type.OBJECT,
    label: 'Time',
    properties: {
      hour: {
        type: Type.NUMBER,
        label: 'Hour',
      },
      minute: {
        type: Type.NUMBER,
        label: 'Minutes',
      },
    },
    isHidden: true,
  },
  dateTime: {
    label: 'Date',
    type: Type.DATE_TIME,
    default: () => new Date(),
    required: true,
  },
};

export const EventSchema: ISchema = {
  type: {
    label: 'Type',
    description:
      '<b class="text-primary">Private</b> can only be managed and viewed internally and used for tracking purposes.\n<b class="text-primary">Public</b> is viewable by anyone and enables features such as ticket sales and booking from public facing apps or webpages.',
    type: Type.STRING,
    enum: EnumHelper.getEnumArray(EventType),
    widget: {
      type: Widget.SELECT,
    },
    default: EventType.PRIVATE,
    immutable: true,
    required: true,
    isHidden: true,
  },
  name: {
    label: 'Name',
    type: Type.STRING,
    required: true,
  },
  description: {
    label: 'Description',
    type: Type.STRING,
  },
  additionalInfo: {
    label: 'Additional Info',
    type: Type.LONG_STRING,
  },
  slug: {
    label: 'Slug',
    type: Type.STRING,
    isHidden: true,
  },
  guestId: {
    label: 'Guest',
    type: Type.STRING,
    isHidden: true,
  },
  classifications: {
    type: Type.ARRAY,
    label: 'Classifications',
    description: 'Select classifications that best describe this event.',
    widget: {
      type: Widget.SELECT,
      multiple: true,
      options: {
        resource: ApiResources.CLASSIFICATIONS,
        version: ApiVersion.V2,
        modelProp: 'name',
        dataProp: 'data.data',
        search: { limit: 1000 },
        displayFields: ['name', 'type'],
      },
    },
    onlyIf: [
      {
        field: 'type',
        shouldShow: type => type === EventType.PUBLIC,
      },
    ],
  },
  dates: {
    type: Type.OBJECT,
    label: 'Dates',
    properties: {
      start: {
        type: Type.OBJECT,
        label: 'Start',
        properties: DateTimeSchema,
        required: true,
      },
      end: {
        type: Type.OBJECT,
        label: 'End',
        properties: DateTimeSchema,
        required: true,
      },
      timezone: {
        type: Type.OBJECT,
        label: 'Timezone',
        isHidden: true,
        properties: {
          name: {
            label: 'Name',
            type: Type.STRING,
          },
          utcOffset: {
            label: 'UTC Offset',
            type: Type.STRING,
          },
        },
      },
    },
  },
};

export interface PriceRange {
  category: EventPriceCategory;
  currency: string;
  min: number;
  max: number;
}
export interface PrivateEvent {
  type: EventType.PRIVATE;
  guestId: string;
}
export interface PublicEvent {
  type: EventType.PUBLIC;
  attractions: string[];
  url?: string;
  locale?: string;
  aliases?: string[];
  ticketLimit?: string;
  accessibility?: string;
  sales: {
    public: {
      startTBD: boolean;
      startDateTime?: Date;
      endDateTime?: Date;
    };
  };
  isActive: boolean;
  priceRanges: PriceRange[];
  images: IImage[];
}
export interface DateTime {
  date: string;
  time: {
    hour: number;
    minute: number;
    seconds?: number;
  };
  dateTime: Date;
}
export interface EventDates {
  start: DateTime;
  end: DateTime;
  access?: {
    startDateTime: Date;
    startApproximate: boolean;
    endDateTime: Date;
    endApproximate: boolean;
  };
  timezone: {
    name: string;
    utcOffset: number;
  };
}

export type EventData = {
  id?: number;
  name: string;
  slug?: string;
  classifications?: string[];
  orgId: string | OrgData;
  venueId: string | VenueData;
  dates: EventDates;
  description?: string;
  info?: string;
  additionalInfo?: string;
  pleaseNote?: string;
  isDeleted?: boolean;
} & (PrivateEvent | PublicEvent) &
  AbstractModelData;

export class Event extends AbstractModel<EventData> {
  constructor(public data: EventData) {
    super(data);
  }

  public static setDateInfo(dateData: EventDates) {
    dateData.start.date = TimeHelper.getDateString(dateData.start.dateTime);
    dateData.start.time = TimeHelper.getHourAndMinute(dateData.start.dateTime);

    dateData.end.date = TimeHelper.getDateString(dateData.end.dateTime);
    dateData.end.time = TimeHelper.getHourAndMinute(dateData.end.dateTime);

    dateData.timezone.name = TimeHelper.getTimezoneFromDate(dateData.start.dateTime);
    dateData.timezone.utcOffset = TimeHelper.utcOffset(dateData.start.dateTime);
    return dateData;
  }
}
