import { ExportType, ReportType, UserRoles } from '@app/enums';
import { PdfHelper } from '../../helpers';
import { PdfContent, PdfSectionCreateParams } from '../../helpers/pdf.helper';
import { ISchema, Type } from '../schema';
import { Report, ReportPeriodParams } from './report';
import { SalesReportResponseData } from './report-export-summaries';
import {
  AccountCodeSalesSummary,
  BehaviorSalesSummary,
  ClassCodeSalesSummary,
  DiscountsSummary,
  EmployeeSalesSummary,
  getReportPeriod,
  LocationCategorySalesSummary,
  LocationSalesSummary,
  ModifierProductCategorySalesSummary,
  ModifierProductSalesSummary,
  ModifierProductSalesByBehaviorSummary,
  ModifierProductSalesByEmployeeSummary,
  OrdersSummary,
  PrintedAt,
  ProductCategorySalesSummary,
  ProductSalesByBehaviorSummary,
  ProductSalesByEmployeeSummary,
  ProductSalesSummary,
  SalesSummary,
  ServiceChargesSummary,
  ShiftSalesSummary,
  StatCodeSalesSummary,
  StatusSalesSummary,
  TaxesSummary,
  TransactionDisclaimer,
  TransactionsSummaryForOrders,
  VendorCodeSalesSummary,
  RefundTransactionsSummaryForOrders,
  ProductSalesByEventSummary,
  EventSalesSummary,
  ModifierProductSalesByEventSummary,
  TransactionsSummaryByShifts,
  RefundTransactionsSummaryByShifts,
  TransactionForPeriodDisclaimer,
  TransactionsSummaryForPeriod,
  RefundTransactionsSummaryForPeriod,
  VoidsSummary,
  AccountCodeSalesByLocationSummary,
  ClassCodeSalesByLocationSummary,
  StatCodeSalesByLocationSummary,
  VendorCodeSalesByLocationSummary,
  ServiceChargesByEmployeeSummary,
  ModifierProductSalesByLocation,
  ProductSalesByLocation,
} from './report-sections';

export const SalesReportSchema: ISchema = {
  from: {
    section: 'basic',
    description: 'The starting period',
    type: Type.DATE,
    default: () => new Date(),
    label: 'Start Date',
    required: true,
  },
  to: {
    section: 'basic',
    description: 'The end of a the period',
    type: Type.DATE,
    label: 'End Date',
  },
};

export interface SalesReportData {
  from: string;
  to: string;
}

export class SalesReport extends Report<SalesReportData> {
  ignoreProperties = [];
  icon = 'fas fa-chart-bar';
  title = 'Sales Report';
  description =
    'Export all sales related information for orders placed during a particular period. This includes, high level sales, discounts, taxes, gratuities, shifts, tenders, products, categories and accounting related information.';
  data: SalesReportData = {} as SalesReportData;
  isImmediate = true;
  schema: ISchema = SalesReportSchema;
  roles: UserRoles[] = [UserRoles.ADMIN, UserRoles.CUSTOMER];
  type: ReportType = ReportType.SALES;
  exportOptions: ExportType[] = [ExportType.PDF];

  pdfSectionCreateParams: PdfSectionCreateParams<SalesReportResponseData>[] = [
    OrdersSummary,
    SalesSummary,
    VoidsSummary,
    StatusSalesSummary,
    BehaviorSalesSummary,
    EventSalesSummary,
    TransactionsSummaryForOrders,
    TransactionDisclaimer,
    RefundTransactionsSummaryForOrders,
    TransactionDisclaimer,
    TransactionsSummaryForPeriod,
    TransactionForPeriodDisclaimer,
    RefundTransactionsSummaryForPeriod,
    TransactionForPeriodDisclaimer,
    TaxesSummary,
    DiscountsSummary,
    ServiceChargesSummary,
    ServiceChargesByEmployeeSummary,
    LocationSalesSummary,
    LocationCategorySalesSummary,
    ProductSalesByLocation,
    ModifierProductSalesByLocation,
    ShiftSalesSummary,
    EmployeeSalesSummary,
    TransactionsSummaryByShifts,
    RefundTransactionsSummaryByShifts,
    ProductCategorySalesSummary,
    ModifierProductCategorySalesSummary,
    ProductSalesSummary,
    ModifierProductSalesSummary,
    ProductSalesByBehaviorSummary,
    ModifierProductSalesByBehaviorSummary,
    ProductSalesByEventSummary,
    ModifierProductSalesByEventSummary,
    ProductSalesByEmployeeSummary,
    ModifierProductSalesByEmployeeSummary,
    AccountCodeSalesSummary,
    ClassCodeSalesSummary,
    StatCodeSalesSummary,
    VendorCodeSalesSummary,
    AccountCodeSalesByLocationSummary,
    ClassCodeSalesByLocationSummary,
    StatCodeSalesByLocationSummary,
    VendorCodeSalesByLocationSummary,
  ];

  public generateExport(period: ReportPeriodParams, responseData: SalesReportResponseData): void {
    const pdfContent: PdfContent = PdfHelper.generateContent<SalesReportResponseData>(
      'Sales Report',
      [PrintedAt, getReportPeriod(period, true)].concat(this.pdfSectionCreateParams),
      responseData
    );
    const periodAsString = [period.from, period.to].filter(val => !!val).join('_');
    PdfHelper.generatePdf(pdfContent, `sales-report_${periodAsString}`);
  }
}
