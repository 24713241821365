import { IImageFile } from '@partake/interfaces';
import { VenueType } from '../../enums';
import { EnumHelper } from '../../helpers';
import { AbstractModel, AbstractModelData } from '../abstract.model';
import { ISchema, Type, Widget } from '../schema';
import { Address, AddressSchema } from './address';

export const VenueSchema: ISchema = {
  name: {
    label: 'Name',
    type: Type.STRING,
    required: true,
    onChange: (value: any, data: any) => {
      if (!value) {
        data.slug = '';
        return;
      }
      const cleaned = value.trim().replace(/[\W_]+/g, ' ');
      const lowercase = cleaned.toLowerCase();
      const split = lowercase.split(' ');
      data.slug = split.join('-');
    },
  },
  slug: {
    label: 'Slug',
    type: Type.STRING,
    required: true,
  },
  type: {
    label: 'Type',
    type: Type.STRING,
    widget: {
      type: Widget.SELECT,
    },
    enum: EnumHelper.getEnumArray(VenueType),
    required: true,
  },
  classifications: {
    section: 'classifications',
    label: 'Tags',
    type: Type.ARRAY,
    widget: {
      type: Widget.CLASSIFICATIONS,
    },
  },
  phone: {
    section: 'contact',
    side: 'right',
    label: 'Phone',
    type: Type.STRING,
  },
  website: {
    section: 'contact',
    side: 'right',
    label: 'Website',
    type: Type.STRING,
  },
  externalUrls: {
    section: 'about',
    label: 'External URLs',
    type: Type.ARRAY,
    items: {
      type: Type.OBJECT,
      label: 'External URL',
      properties: {
        label: { type: Type.STRING, label: 'Label', required: true },
        url: { type: Type.STRING, label: 'URL', required: true },
      },
    },
  },
  address: {
    section: 'address',
    label: 'Address',
    type: Type.OBJECT,
    properties: AddressSchema,
  },
  location: {
    label: 'Location',
    type: Type.STRING,
    isHidden: true,
  },
  images: {
    section: 'images',
    side: 'right',
    type: Type.IMAGES,
    label: 'Images',
    bucket: 'venue',
  },
  isPublic: {
    label: 'Public',
    type: Type.BOOLEAN,
  },
  longitude: {
    label: 'Longitude',
    type: Type.NUMBER,
    isHidden: true,
  },
  latitude: {
    label: 'Latitude',
    type: Type.NUMBER,
    isHidden: true,
  },
  loc: {
    label: 'Location',
    type: Type.ARRAY,
    isHidden: true,
  },
};

export interface VenueData extends AbstractModelData {
  name: string;
  slug: string;
  type: VenueType;
  location: string;
  classifications: string[];
  isPublic: boolean;
  isPreview: boolean;
  address: Address;
  phone: string;
  countryCode: string;
  website: string;
  externalUrls: { label: string; url: string }[];
  /** @deprecated */
  imageUrl: string;
  images: IImageFile[];
  longitude: number;
  latitude: number;
  loc: number[];
}

export class Venue extends AbstractModel<VenueData> {
  constructor(public data: VenueData) {
    super(data);
  }
}
