import _ from 'lodash';
import JsPDF from 'jspdf';
import autoTable, { StylesProps } from 'jspdf-autotable';
import { currency, float, int, titlecase } from './string.helper';
import { DateFormat, getDateStringWithFormat } from './time.helper';

export const PageStartAfterTitle = 120;

export const PageStart = 0;

export const PdfWidth = 595;

export const PdfHeight = 842;

export const Margin = 40;

export const TableWidth = PdfWidth - Margin * 2;

export async function downloadImage(url: string): Promise<HTMLImageElement> {
  return new Promise(resolve => {
    const image = new Image();
    image.onload = function loadImage() {
      resolve(image);
    };
    image.src = url;
  });
}

export async function addHeaderImage(doc: any, url: string) {
  const image = await downloadImage(url);
  doc.addImage(image, 'PNG', Margin, 20, 281, 55);
}

export async function addTitle(doc: any, title: string) {
  doc.setFontSize(16);
  doc.setFont(undefined, 'bold');
  doc.text(title, Margin, 100);
}

export interface TextOptions {
  content: string;
  top: number;
  left?: number;
  fontSize?: number;
  fontStyle?: string;
  fontColor?: number[];
}

export function addTextLine(doc: any, options: TextOptions): number {
  const {
    content,
    top,
    left = Margin,
    fontSize = 12,
    fontStyle = 'normal',
    fontColor = [0, 0, 0],
  } = options;
  doc.setFontSize(fontSize);
  doc.setFont(undefined, fontStyle);
  doc.setTextColor(...fontColor);
  const lines = doc.splitTextToSize(content, PdfWidth - left * 2);
  lines.forEach((line: string, idx: number) => {
    doc.text(line, left, top + idx * (fontSize + 3));
  });
  return top + lines.length * (fontSize + 3);
}

type HAlign = 'left' | 'right' | 'center';

type ColumnStyles = StylesProps['columnStyles'];

export interface TableOptions {
  top: number;
  noItemsMessage?: string;
  columns: string[];
  items: string[][];
  theme: 'plain' | 'striped';
  halign?: HAlign[];
  margin?: { right?: number; left?: number };
  paddingTop?: number;
  columnStyles?: ColumnStyles;
  showTotalsRow?: boolean;
}

export function addTable(doc: any, options: TableOptions): number {
  const {
    top,
    columns,
    items,
    margin,
    halign = [],
    noItemsMessage = 'No table items',
    theme = 'plain',
    paddingTop = 0,
    columnStyles,
    showTotalsRow,
  } = options;
  let table: any = null;
  if (!items.length) {
    items.push([noItemsMessage]);
  }
  autoTable(doc, {
    columns,
    body: items,
    pageBreak: 'avoid',
    showHead: 'everyPage',
    startY: top + paddingTop,
    theme,
    headStyles: {
      fillColor: [11, 13, 34],
      textColor: 255,
    },
    ...(columnStyles && { columnStyles }),
    ...(margin && { margin }),
    didParseCell: (cell: any) => {
      if (columns.length < 3 && cell.column.index === 1) {
        cell.cell.styles.halign = 'right';
      }
      if (halign[cell.column.index]) {
        cell.cell.styles.halign = halign[cell.column.index];
      }
      if (showTotalsRow && cell.row.index === items.length - 1) {
        cell.cell.styles.fontStyle = 'bold';
        cell.cell.styles.fillColor = [242, 242, 242];
        cell.cell.styles.textColor = 0;
      }
    },
    didDrawPage: (cell: any) => {
      table = cell.table;
    },
  });
  if (margin?.right) {
    return top;
  }
  return table.finalY + 20;
}

interface PdfHeaderImage {
  url: string;
}

enum PdfSectionType {
  TABLE = 'table',
  TEXT = 'text',
}

interface PdfTextSection {
  type: PdfSectionType.TEXT;
  options: Omit<TextOptions, 'top'>;
}

interface PdfTableSection {
  type: PdfSectionType.TABLE;
  options: Omit<TableOptions, 'top'>;
  title?: Omit<TextOptions, 'top'>;
}

type PdfSection = PdfTextSection | PdfTableSection;

export interface PdfContent {
  headerImage?: PdfHeaderImage;
  title: string;
  sections: PdfSection[];
}

function getTableHeight(pdfSection: PdfTableSection): number {
  const tableRowHeight = 20;
  const itemHeight = pdfSection.options.items.length * tableRowHeight;
  if (pdfSection.options.showTotalsRow) {
    return itemHeight + tableRowHeight * 2;
  }
  return itemHeight + tableRowHeight;
}

function addFooters(doc: any) {
  const pageCount = doc.internal.getNumberOfPages();
  for (let i = 1; i <= pageCount; i++) {
    const pageInfoStr = `${i} / ${pageCount}`;
    doc.setPage(i);
    doc.setFontSize(12);
    doc.setTextColor(...[0, 0, 0]);
    doc.setFont(undefined, 'normal');
    doc.text(pageInfoStr, PdfWidth - Margin - pageInfoStr.length * 6, PdfHeight - Margin * 0.5);
  }
}

export async function generatePdf(pdfContent: PdfContent, fileName: string) {
  const doc = new JsPDF({ orientation: 'p', unit: 'pt', format: 'a4' });

  const { headerImage, title, sections } = pdfContent;
  await addHeaderImage(doc, headerImage.url);
  addTitle(doc, title);

  let top = PageStartAfterTitle;
  sections.forEach((section: PdfSection) => {
    if (section.type === 'text') {
      const textOptions = {
        ...section.options,
        top,
      };
      top = addTextLine(doc, textOptions);
    }
    if (section.type === 'table') {
      // If we cant fit the title and table in this space - add a page
      if (PdfHeight - top < getTableHeight(section) + Margin + 40) {
        doc.addPage();
        top = PageStart;
      }
      if (section.title) {
        top = addTextLine(doc, {
          ...section.title,
          top: top + Margin,
        });
      }
      const tableOptions = {
        ...section.options,
        top,
      };
      top = addTable(doc, tableOptions);
    }
  });

  addFooters(doc);

  doc.save(`${fileName}.pdf`);
}

interface Default {
  label: string;
}

interface Title {
  type: 'title';
  options?: {
    fontStyle: 'normal' | 'italic' | 'bold';
    fontColor: number[];
  };
}

type TextFieldFormat = 'currency' | 'int' | 'float' | 'string' | 'custom' | 'date';

interface TextField {
  type: 'text';
  dataProperty: string | string[];
  format?: TextFieldFormat;
  halign?: HAlign;
  dateFormat?: string;
  showTotal?: boolean;
  formatter?: (value: string) => string;
}

interface CustomTable<Data> {
  type: 'custom-table';
  theme: 'plain' | 'striped';
  dataProperty: string | string[];
  children: PdfSectionCreateParams<Data>[];
  columns: string[];
  margin?: { right?: number; left?: number };
  paddingTop?: number;
  columnStyles?: ColumnStyles;
}

export type TableRow = TextField & Default;

interface Table {
  type: 'table';
  theme: 'plain' | 'striped';
  dataProperty: string | string[];
  groupField?: string;
  groupLabelProperty?: string | string[];
  noItemsMessage: string;
  rows: TableRow[];
  margin?: { right?: number; left?: number };
  paddingTop?: number;
  columnStyles?: ColumnStyles;
  sort?: {
    field: string;
    reverse?: boolean;
  };
  showTotalsRow?: boolean;
}

export type PdfSectionCreateParams<Data> = Default &
  (Title | CustomTable<Data> | Table | TextField);

function formatField(format: TextFieldFormat, value: any): string {
  if (_.isNull(value) || _.isUndefined(value)) {
    return '';
  }
  switch (format) {
    case 'currency':
      return currency(value);
    case 'float':
      return float(value);
    case 'int':
      return int(value);
    case 'string':
      return titlecase(value);
    case 'date': {
      return getDateStringWithFormat(value, DateFormat.MEDIUM_TIME);
    }
  }
  return `${value}`;
}

function getProperties(data: any, properties: string | string[]) {
  if (_.isArray(properties)) {
    return properties
      .map(property => _.get(data, property))
      .filter(value => value)
      .join(' / ');
  }
  return _.get(data, properties);
}

function getSections<Data>(section: PdfSectionCreateParams<Data>, data: Data): PdfSection[] {
  if (section.type === 'title') {
    return [
      {
        type: PdfSectionType.TEXT,
        options: {
          content: section.label,
          ...section.options,
        },
      },
    ];
  }
  if (section.type === 'text') {
    const value = getProperties(data, section.dataProperty);
    let content: string;
    if (section.format === 'custom') {
      content = section.formatter(value);
    } else {
      content = formatField(section.format, value);
    }
    return [
      {
        type: PdfSectionType.TEXT,
        options: {
          content,
        },
      },
    ];
  }
  if (section.type === 'custom-table') {
    return [
      {
        type: PdfSectionType.TABLE,
        options: {
          theme: section.theme,
          margin: section.margin,
          paddingTop: section.paddingTop,
          columnStyles: section.columnStyles,
          columns: section.columns || section.children.map(childSection => childSection.label),
          items: section.children.map(childSection => {
            const [sectionContent]: PdfSection[] = getSections(
              childSection,
              getProperties(data, section.dataProperty)
            );
            if (sectionContent.type === 'text') {
              return [childSection.label, sectionContent.options.content];
            }
            return [''];
          }),
        },
      },
    ];
  }
  if (section.type === 'table') {
    const tableData: any[] = getProperties(data, section.dataProperty);
    const { groupField, groupLabelProperty, ...sectionOpts } = section;
    if (groupField) {
      const flattenedGroups = _.groupBy(tableData, section.groupField);
      return _.keys(flattenedGroups)
        .filter((key: string) => !!key && key !== 'undefined')
        .map((key: string) => {
          const groupData = flattenedGroups[key];
          const labelTemplate = sectionOpts.label;
          let label: string;
          if (_.isArray(groupLabelProperty)) {
            label = groupLabelProperty.reduce((prev: string, property: string) => {
              const labelData = getProperties(groupData[0], property);
              if (!labelData) {
                return prev.replace(`{{${property}}}`, 'Unknown');
              }
              const labelText = formatField('string', labelData);
              return prev.replace(`{{${property}}}`, labelText);
            }, labelTemplate);
          } else {
            const labelText = formatField(
              'string',
              getProperties(groupData[0], groupLabelProperty)
            );
            label = labelTemplate.replace(`{{${groupLabelProperty}}}`, labelText);
          }
          const tempSection: PdfSectionCreateParams<{ [key: string]: any }> = {
            ...sectionOpts,
            label,
            dataProperty: key,
          };
          const [pdfSection] = getSections(tempSection, { [key]: groupData });
          return pdfSection;
        });
    }
    const table: PdfTableSection = {
      type: PdfSectionType.TABLE,
      title: {
        content: section.label,
        fontStyle: 'bold',
        fontSize: 14,
        fontColor: [11, 13, 34],
      },
      options: {
        theme: section.theme,
        margin: section.margin,
        paddingTop: section.paddingTop,
        noItemsMessage: section.noItemsMessage,
        columns: section.rows.map(row => row.label),
        halign: section.rows.map(row => row.halign),
        columnStyles: section.columnStyles,
        showTotalsRow: !!(section.showTotalsRow && tableData.length),
        items: tableData
          .sort((a: any, b: any) => {
            const sortField = section.sort?.field;
            if (!sortField) {
              return 0;
            }
            if (section.sort?.reverse && a[sortField] < b[sortField]) {
              return 1;
            }
            if (section.sort?.reverse && a[sortField] > b[sortField]) {
              return -1;
            }
            if (!section.sort?.reverse && a[sortField] < b[sortField]) {
              return -1;
            }
            if (!section.sort?.reverse && a[sortField] > b[sortField]) {
              return 1;
            }
            return 0;
          })
          .map((rowData: any) =>
            section.rows.map(row => {
              const [sectionContent]: PdfSection[] = getSections(row, rowData);
              if (sectionContent.type === 'text') {
                return sectionContent.options.content;
              }
              return '';
            })
          ),
      },
    };
    if (table.options.showTotalsRow) {
      const totalsRow = section.rows.map(row => {
        if (row.showTotal) {
          const total = tableData.reduce(
            (sum: number, rowData: any) => getProperties(rowData, row.dataProperty) + sum,
            0
          );
          return formatField(row.format, total);
        }
        return '';
      });
      table.options.items.push(totalsRow);
    }
    return [table];
  }
}

export function generateContent<Data>(
  title: string,
  sections: PdfSectionCreateParams<Data>[],
  data: Data
): PdfContent {
  const pdfContent: PdfContent = {
    headerImage: { url: 'assets/images/logo/logo.png' },
    title,
    sections: [],
  };
  sections.forEach(section => {
    console.log(section.label);
    const pdfSections: PdfSection[] = getSections(section, data);
    pdfSections.forEach(pdfSection => {
      pdfContent.sections.push(pdfSection);
    });
  });
  return pdfContent;
}
