export enum BillingCycle {
  MONTHLY = 'monthly',
  ANNUAL = 'annual',
}

export enum OrgState {
  LIVE = 'live',
  NON_LIVE = 'non-live',
  STALE = 'stale',
}

export enum OrgFeature {
  POS = 'pos',
  KDS = 'kds',
  BILLING = 'billing',
  ONLINE_ORDERING = 'online-ordering',
  EVENTS = 'events',
  GAMES = 'games',
  FANTASY_SPORTS = 'fantasy-sports',
  ADVANCED_REPORTING = 'advanced-reporting',
  MARKETING = 'marketing',
  TEE_SHEET = 'tee-sheet',
  LOYALTY = 'loyalty',
  MENU_SECTIONS = 'menu-sections',
  MEMBER_CHARGES = 'member-charges',
}

export enum CustomStringKey {
  SIGNUP_TITLE = 'signup-title',
  SIGNUP_DESCRIPTION = 'signup-description',
  SIGNUP_BUTTON = 'signup-button',
  SIGNUP_LOGIN = 'signup-login',
  SIGNUP_LOGIN_BUTTON = 'signup-login-button',
  LOGIN_TITLE = 'login-title',
  LOGIN_DESCRIPTION = 'login-description',
  LOGIN_BUTTON = 'login-button',
  LOGIN_SIGNUP = 'login-signup',
  LOGIN_SIGNUP_BUTTON = 'login-signup-button',
  LOGIN_FORGOT_PASSWORD = 'login-forgot-password',
  LOGIN_FORGOT_PASSWORD_BUTTON = 'login-forgot-password-button',
  ADD_PHONE_TITLE = 'add-phone-title',
  ADD_PHONE_DESCRIPTION = 'add-phone-description',
  ADD_PHONE_BUTTON = 'add-phone-button',
  ACCOUNT_LANDING_TITLE = 'account-landing-title',
  ACCOUNT_LANDING_DESCRIPTION = 'account-landing-description',
  NO_ORDERS_TITLE = 'no-orders-title',
  NO_ORDERS_DESCRIPTION = 'no-orders-description',
  NO_ORDERS_BUTTON = 'no-orders-button',
  NO_USER_ORDERS_TITLE = 'no-user-orders-title',
  NO_USER_ORDERS_DESCRIPTION = 'no-user-orders-description',
}
